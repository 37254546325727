.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.App-background {
  background-image: url('/assets/likethewolf-wallpaper2.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
}
